import React, { useState } from 'react';
import { Dialog as MuiDialog, DialogContent, makeStyles } from '@material-ui/core';
import ReportPopup, { DialogTitle } from './reports/ReportCommonPopup';
import { LoadingIndicator } from './reports/reconcilationreport/MuiStyled';

const useStyles = makeStyles(() => ({
    dialogheader: {
        minHeight: 28,
        textAlign: 'center',
        fontSize: '0.75rem',
        '& span': {
            fontWeight: 'bold'
        },
        '& .modelheader': {
            paddingBottom: '5px',
            fontWeight: 'bold'
        }
    },
}));
const Dialog = ({
    isOpen,
    updateDialogStatus,
    title,
    dialogContent,
    popupGridData,
    popupColumns,
    enablePagination = true,
    isLoading = false,
    customRowHeight,
    dialogTitleClassName = ""
}) => {
    const classes = useStyles();
    const [dialogState, setDialogState] = useState("minimized");
    const onMinimize = () => {
        setDialogState("minimized");
    }
    const onMaximize = () => {
        setDialogState("maximized");
    }
    return (
        <MuiDialog open={isOpen} maxWidth={dialogState === "minimized" ? "md" : "lg"} fullWidth={true} PaperProps={{
            style: {
                width: dialogState === "minimized" ? "860px" : "100%",
                height: dialogState === "minimized" ? "auto" : "100%",
                maxHeight: dialogState === "minimized" ? "auto" : "100%"
            }
        }}>
            <DialogTitle classes={{
                root: dialogTitleClassName
            }}
                dialogState={dialogState}
                onClose={() => { updateDialogStatus(false) }}
                onMinimize={onMinimize}
                onMaximize={onMaximize}
            >
                <div className={classes.dialogheader}>
                    {title}
                </div>
            </DialogTitle>
            <DialogContent>
                {isLoading && <LoadingIndicator />}
                {dialogContent || (<ReportPopup
                    customRowHeight={customRowHeight}
                    enablePagination={enablePagination}
                    modelData={popupGridData}
                    columns={popupColumns} />)}

            </DialogContent>
        </MuiDialog>
    )
}
export default Dialog;