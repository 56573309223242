import React, { useState } from 'react';
import moment from 'moment';
import DataGrid from '../grid';
import { IconButton, Tooltip, withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Pagination from '../grid/Pagination';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { v2UIRoutePath } from '../../../common/commons';
import { CheckBoxOutlineBlank, Minimize } from '@material-ui/icons';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: '16px 16px 0 16px',
    },
    iconsContainter: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
    },
    closeButton: {

        color: theme.palette.grey[500],
    },
});

function ReportPopup(props) {
    // State used to handle the Active page
    const [currentPage, setCurrentPage] = useState(1);

    const { enablePagination = true, columns, customRowHeight = 30 } = props;
    /**
     * This Method updates the current active page to load on to the grid
     * @param {Active Page to load} page 
     */
    const handlePageChange = (page) => {
        // Updating the Active Page to load
        setCurrentPage(page);
    }
    // Limiting the page Size on the grid to 75
    const pageSize = 75;
    // Calculating the Grid Start Index based on the loaded page
    const startPageIndex = ((currentPage - 1) * pageSize);
    // Loading only the current page data into the toloadPage Variable
    const toLoadPage = props.modelData.slice(startPageIndex, startPageIndex + pageSize);
    // Formating the Data based on the columns for tooltip on the Time columns
    const rows = toLoadPage?.map((_model) => {
        const { dsar_id, ticket_type, created_date, requestor } = _model;
        return {
            id: dsar_id,
            dsrId: dsar_id,
            requesttype: ticket_type,
            requestor,
            createdDateandTimeLocal: created_date ? moment(created_date).local().format('MMM Do, YYYY HH:mm:ss [(local)]') : '--',
            createdDateandTime: created_date ? moment(created_date).format("MM-DD-YYYY") : '--',
            createdDateandTimeUTC: created_date ? moment(created_date).utc().format("MMM Do, YYYY HH:mm:ss (UTC)") : '--',
            ..._model
        }
    })
    return (
        <>
            <DataGrid
                customRowHeight={customRowHeight}
                enabledarktheme={true}
                columns={columns || [{
                    name: "Request ID",
                    mapping: "dsrId",
                    renderCell: (params) => (
                        <Link to={`${v2UIRoutePath}requestdetails/` + params.row["dsrId"]}>
                            {params.row["dsrId"]}
                        </Link>
                    )
                }, {
                    name: "Request Type",
                    mapping: "requesttype",
                    width: 150,
                }, {
                    name: "Requestor",
                    mapping: "requestor",
                    width: 95,
                }, {
                    name: "Created Date",
                    mapping: "createdDateandTime",
                    width: 200,
                    renderCell: (params) => (
                        <Tooltip title={params.row["createdDateandTimeLocal"]}>
                            <span>
                                {params.row["createdDateandTimeUTC"]}
                            </span>
                        </Tooltip>
                    ),
                }]}
                customHeight={350}
                rows={rows}
            />
            {enablePagination && (<Pagination
                currentPage={currentPage}
                pageCount={75}
                totalResults={props.modelData?.length}
                pageChange={handlePageChange}
            />)}
        </>
    );
}

export const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, dialogState, onMinimize, onMaximize, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <div>{children}</div>
            <div className={classes.iconsContainter}
            >
                {dialogState === 'maximized' ? (
                    <IconButton data-testid={"dialog-minimize-button"} aria-label="minimize" className={classes.closeButton} onClick={onMinimize}>
                        <Minimize />
                    </IconButton>
                ): (
                    <IconButton data-testid={"dialog-maximize-button"} aria-label="maximize" className={classes.closeButton} onClick={onMaximize}>
                        <CheckBoxOutlineBlank />
                    </IconButton>
                )}
                {onClose ? (
                    <IconButton data-testid={"dialog-close-button"} aria-label="close" className={classes.closeButton} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </div>
        </MuiDialogTitle>
    );
});

export default ReportPopup;
